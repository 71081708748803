import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import bgSrc from 'assets/Home/home_bg.jpg';
import analytics from 'utils/analytics';
import { desktop } from '../utils/mediaQueries';
import Footer from './Footer';
import GlobalEconomy from './GlobalEconomy';
import KeyVisual from './KeyVisual';
import Navbar from './Navbar';
import Quotes from './Quotes';
import SimpleSteps from './SimpleSteps';
import StepsCounter from './StepsCounter';
import SweatcoinBanner from './SweatcoinBanner';
import SweatcoinValue from './SweatcoinValue';
import TopCountries from './TopCountries';
import Vision from './Vision';
const messages = defineMessages({
    title: 'It pays to walk',
    subtitle: 'Turn your steps into real-life rewards',
    button: 'How to Spend',
    download: 'Download the App',
});
const configSelector = (state) => state.config;
const Content = () => {
    // Config & firebase
    const config = useSelector(configSelector);
    const { origin } = config;
    useEffect(() => {
        if (typeof document !== 'undefined') {
            const url = window.location.href.split('/');
            const target = url[url.length - 1].toLowerCase();
            const element = target ? document.getElementById(target) : null;
            if (element) {
                const scrolled = window.pageYOffset || document.documentElement.scrollTop;
                window.scrollTo({
                    top: scrolled + element.getBoundingClientRect().top,
                    behavior: 'smooth',
                });
            }
        }
    }, []);
    return (React.createElement(Wrapper, null,
        React.createElement(Navbar, null,
            React.createElement(FormattedMessage, { ...messages.download }, (msg) => (React.createElement(Anchor, { href: "#download", onClick: (event) => scrollTo(event, 'download', -100) }, msg)))),
        React.createElement(Title, null,
            React.createElement(FormattedMessage, { ...messages.title })),
        React.createElement(Subtitle, null,
            React.createElement(FormattedMessage, { ...messages.subtitle })),
        React.createElement(KeyVisual, null),
        React.createElement(Center, null,
            React.createElement(Button, { href: "#download", onClick: (event) => scrollTo(event, 'download') },
                React.createElement(FormattedMessage, { ...messages.download }))),
        React.createElement(Vision, null),
        React.createElement(Quotes, null),
        React.createElement(TopCountries, null),
        React.createElement(SimpleSteps, null),
        React.createElement(StepsCounter, null),
        React.createElement(GlobalEconomy, null),
        React.createElement(SweatcoinValue, null),
        React.createElement(SweatcoinBanner, null),
        React.createElement(Footer, { bgColor: 'transparent' })));
    function select(selector) {
        if (typeof document !== 'undefined') {
            return document.getElementById(selector);
        }
        else {
            return [];
        }
    }
    function scrollTo(event, section_id, top = 1) {
        event.preventDefault();
        if (section_id === 'download') {
            analytics.track('MainPageDownloadButton', 'click', pickBy({
                origin,
                feature: 'main_page_download_button',
            }, identity));
        }
        const scroll_to = select(section_id);
        if (scroll_to) {
            const scrolled = window.pageYOffset || document.documentElement.scrollTop;
            window.scrollTo({
                top: scrolled + scroll_to.getBoundingClientRect().top + top,
                behavior: 'smooth',
            });
        }
    }
};
const Wrapper = styled.div `
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding-top: 140px;
  background: url(${bgSrc}) top center no-repeat;
  background-size: cover;

  @media ${desktop()} {
    padding-top: 180px;
    margin: 0 auto;
  }
`;
const Title = styled.h1 `
  font-weight: 800;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 40px;
  letter-spacing: -0.3px;
  line-height: 1.2;
  text-align: center;

  @media ${desktop()} {
    font-size: 74px;
  }
`;
const Subtitle = styled.h2 `
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
  margin: 12px 0 0;
  padding: 0 16px;

  @media ${desktop()} {
    max-width: 640px;
    font-size: 18px;
    margin: 10px auto 40px;
  }
`;
const Center = styled.div `
  text-align: center;
`;
const Button = styled.a `
  box-sizing: border-box;
  display: inline-block;
  padding: 17px 30px;
  box-shadow: 0px 8px 16px 0px rgba(152, 107, 82, 0.16);
  background: linear-gradient(102deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.7) 100%);
  border-radius: 12px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  color: #001430;
  font-size: 19px;
  letter-spacing: -0.6px;
  line-height: 1.2;
  text-align: center;
`;
const Anchor = styled.a `
  padding: 8px 12px;
  display: inline-block;
  box-shadow: 0px 8px 16px 0px rgba(152, 107, 82, 0.16);
  background: linear-gradient(102deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.7) 100%);
  border-radius: 16px;
  border: none;
  font-weight: 700;
  font-style: normal;
  font-family: 'Inter', sans-serif;
  color: #001430 !important;
  font-size: 12px;
  line-height: 1.4;
  margin-left: 36px;
`;
export default Content;
