import React, { useEffect } from 'react';
import styled from 'styled-components';
import FONTS from 'assets/fonts';
import fbinstall from '../assets/fbinstall.webp';
const Container = styled.div `
  height: 100dvh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #a4a3c2 0%, #c3bacd 100%);
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  padding-bottom: env(safe-area-inset-bottom);

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
    pointer-events: none;
  }
`;
const Content = styled.div `
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 0;
  overflow: hidden;
  padding: 10px 10px 80px 10px;
  box-sizing: border-box;
`;
const Title = styled.h1 `
  color: white;
  text-align: center;
  margin: 80px 0 20px;
  font-size: 56px;
  font-weight: 600;
  font-family: ${FONTS.SimplonNormBold.name}, ${FONTS.fallback};
  flex-shrink: 0;
`;
const Image = styled.img.attrs({
    src: fbinstall,
    alt: 'Facebook Install',
}) `
  width: calc(100% + 40px);
  margin-left: -20px;
  height: auto;
  max-height: calc(100% - 120px);
  object-fit: contain;
  flex-shrink: 1;
`;
const StyledLink = styled.a `
  width: calc(100% - 20px);
  margin: 0 auto 15px;
  background-color: #ff4444;
  color: white;
  border: none;
  padding: 16px 32px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: block;
  position: relative;
  z-index: 1;
  flex-shrink: 0;

  &:visited {
    color: white;
  }
`;
const FBInstall = () => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);
    return (React.createElement(Container, null,
        React.createElement(Content, null,
            React.createElement(Title, null, "Vale la pena Caminar!"),
            React.createElement(Image, null)),
        React.createElement(StyledLink, { href: "https://itunes.apple.com/app/sweatcoin-app-that-pays-you/id971023427", target: "_blank", rel: "noopener noreferrer" }, "Install Now")));
};
export default FBInstall;
