import _ from 'lodash';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { ParallaxProvider } from 'react-scroll-parallax';
import { createGlobalStyle } from 'styled-components';
import * as ApiActions from 'actions/api';
import FONTS from 'assets/fonts';
import { media } from 'components/responsive';
import Content from '../components/Content';
const GlobalStyle = createGlobalStyle `
html, body {
  font-size: 17px !important;
  font-family: ${FONTS.SimplonNorm.name}, ${FONTS.fallback} !important;
  
  ${media.phone_medium `font-size: 22px !important;`};
  ${media.tablet `font-size: 30px !important;`};
  ${media.desktop `font-size: 26px !important;`};
  ${media.desktop_large `font-size: 34px !important;`};
}
`;
const mapStateToProps = (state) => ({
    onEnter: _.get(state, 'onEnter', false),
});
class Home extends React.PureComponent {
    static fetchData(state, store) {
        return Promise.all([store.dispatch(ApiActions.prefetchGlobalStats(true))]);
    }
    render() {
        const { onEnter } = this.props;
        return onEnter.redirectUrl ? (React.createElement(Redirect, { to: onEnter.redirectUrl })) : (React.createElement(ParallaxProvider, null,
            React.createElement(Helmet, { meta: [
                    { name: 'event-id', content: 'index' },
                    {
                        name: 'apple-itunes-app',
                        content: 'app-id=971023427',
                    },
                ], link: [
                    {
                        rel: 'preconnect',
                        href: 'https://fonts.googleapis.com',
                    },
                    {
                        rel: 'preconnect',
                        href: 'https://fonts.gstatic.com',
                        crossOrigin: 'crossorigin',
                    },
                    {
                        rel: 'stylesheet',
                        href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&family=Prompt&family=Inconsolata:wght@800&display=swap',
                    },
                ] }),
            React.createElement(GlobalStyle, null),
            React.createElement(Content, null)));
    }
}
export default connect(mapStateToProps)(Home);
