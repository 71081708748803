import React from 'react';
import styled from 'styled-components';
import swc from 'assets/Home/swc_logo.png';
const Footer = () => {
    return (React.createElement(Wrapper, null,
        React.createElement(Title, null,
            React.createElement(SWC, { src: swc }),
            React.createElement("span", null, "Copyright, 2025 Sweatcoin Ltd."))));
};
const Wrapper = styled.div `
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 36px 0px;
`;
const Title = styled.p `
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.002em;
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
`;
const SWC = styled.img `
  width: 25px;
  display: inline-block;
  margin-right: 12px;
  opacity: 0.6;
`;
export default Footer;
