import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, FormattedNumber, defineMessages } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { desktop } from '../utils/mediaQueries';
const messages = defineMessages({
    registeredUsersCaption: 'Users globally',
    top3: 'Top-3',
    healthAndFintessApp: 'Health & Fitness app worldwide',
    leftTitle1: 'Walk and earn exclusive rewards',
    leftSubtitle1: 'Every step counts—literally',
    leftTitle2: 'Track your progress',
    leftSubtitle2: 'Get personalized movement and health stats that keep you on track',
    leftTitle3: 'Build a lasting habit',
    leftSubtitle3: 'Research proves it: Sweatcoin users walk 20% more—even six months later',
});
const statsSelector = (state) => state.stats;
const Vision = ({}) => {
    const stats = useSelector(statsSelector);
    const defaultUsersGloballyAmount = 180000000;
    const defaultUsersGloballyPerSecond = 1.6;
    const [usersGlobally, setUsersGlobally] = useState(0);
    const frame = useRef(0);
    useEffect(() => {
        if (stats) {
            let initialSweatcoins = _.get(stats, 'users_globally', defaultUsersGloballyAmount);
            const sweatcoinsPerSecond = _.get(stats, 'users_globally_per_second', defaultUsersGloballyPerSecond);
            const animate = () => {
                setUsersGlobally((initialSweatcoins += sweatcoinsPerSecond / 60));
                frame.current = requestAnimationFrame(animate);
            };
            frame.current = requestAnimationFrame(animate);
            return () => cancelAnimationFrame(frame.current);
        }
    }, [stats]);
    return (React.createElement(Wrapper, null,
        React.createElement(Text, null,
            React.createElement(TextTitle, null,
                React.createElement(FormattedMessage, { ...messages.leftTitle1 })),
            React.createElement(TextSubtitle, null,
                React.createElement(FormattedMessage, { ...messages.leftSubtitle1 })),
            React.createElement(TextTitle, null,
                React.createElement(FormattedMessage, { ...messages.leftTitle2 })),
            React.createElement(TextSubtitle, null,
                React.createElement(FormattedMessage, { ...messages.leftSubtitle2 })),
            React.createElement(TextTitle, null,
                React.createElement(FormattedMessage, { ...messages.leftTitle3 })),
            React.createElement(TextSubtitle, null,
                React.createElement(FormattedMessage, { ...messages.leftSubtitle3 }))),
        React.createElement(Numbers, null,
            React.createElement(NumbersTitle, null, usersGlobally ? (React.createElement(FormattedNumber, { value: usersGlobally, maximumFractionDigits: 0 })) : (React.createElement(FormattedNumber, { value: 0, maximumFractionDigits: 0 }))),
            React.createElement(NumbersSubtitle, null,
                React.createElement(FormattedMessage, { ...messages.registeredUsersCaption })),
            React.createElement(NumbersTitle, null,
                React.createElement(FormattedMessage, { ...messages.top3 })),
            React.createElement(NumbersSubtitle, null,
                React.createElement(FormattedMessage, { ...messages.healthAndFintessApp })))));
};
const Wrapper = styled.div `
  margin: 50px 16px 16px;
  position: relative;
  display: flex;
  flex-direction: column;

  @media ${desktop()} {
    width: 90vw;
    padding: 150px 0 120px;
    margin: 0 auto;
    flex-direction: row;
    justify-content: center;
  }
`;
const TextSubtitle = styled.p `
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 18px;

  strong {
    color: #fff;
  }

  @media ${desktop()} {
    font-size: 24px;
    line-height: 1.6;
    margin-bottom: 34px;
  }
`;
const TextTitle = styled(TextSubtitle) `
  font-weight: 700;
  margin: 0;
  padding: 0;
`;
const Text = styled.div `
  @media ${desktop()} {
    width: 480px;
    flex: none;
    margin-right: 100px;
  }
`;
const Numbers = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media ${desktop()} {
    min-width: 460px;
  }
`;
const NumbersTitle = styled.h4 `
  font-weight: 800;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 72px;
  letter-spacing: -1px;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(151, 143, 255, 1) 100%);
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  margin-bottom: 16px;

  @media ${desktop()} {
    margin-bottom: 20px;
  }
`;
const NumbersSubtitle = styled.p `
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 34px;

  @media ${desktop()} {
    margin-bottom: 70px;
  }
`;
export default Vision;
