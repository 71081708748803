import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import facebook from 'assets/Home/footer_facebook.png';
import instagram from 'assets/Home/footer_instagram.png';
import linkedin from 'assets/Home/footer_linkedin.png';
import x from 'assets/Home/x.svg';
import swc from 'assets/Home/swc_logo.svg';
import tiktok from 'assets/Home/tiktok.svg';
import CountriesList from 'constants/Countries.json';
import analytics from 'utils/analytics';
import { desktop } from '../utils/mediaQueries';
const messages = defineMessages({
    team: 'Team',
    contact: 'Contact',
    tnc: 'T&C',
    privacy: 'Privacy Policy',
    help: 'Help',
    blog: 'Blog',
    dev: 'Dev Blog',
    influencers: 'Become an Influencer',
    vision: 'Vision',
    facts: 'Facts',
    how_it_works: 'How it works',
    partners: 'Partners',
});
const countrySelector = (state) => state.config.country;
const Footer = ({ className, bgColor }) => {
    const country = useSelector(countrySelector);
    const { path } = useRouteMatch();
    return (React.createElement(Wrapper, { className: className, bgColor: bgColor },
        React.createElement(Content, null,
            React.createElement(Side, null,
                React.createElement(Copyright, null,
                    React.createElement(SWC, { src: swc, width: "40", height: "40", alt: "Sweatcoin logo" }),
                    React.createElement(Text, null, "Copyright \u00A9 2025 Sweatco Ltd"))),
            React.createElement(Side, null,
                React.createElement(Column, null,
                    React.createElement(Link, { href: "https://sweatcoin.teamtailor.com/", onClick: () => trackFooterLinkClick('team'), rel: "noreferrer noopener", target: "_blank" },
                        React.createElement(FormattedMessage, { ...messages.team })),
                    React.createElement(StyledRouterLink, { to: "/contacts", onClick: () => trackFooterLinkClick('contacts') },
                        React.createElement(FormattedMessage, { ...messages.contact })),
                    React.createElement(StyledRouterLink, { to: "/tnc", onClick: () => trackFooterLinkClick('tnc') },
                        React.createElement(FormattedMessage, { ...messages.tnc })),
                    React.createElement(StyledRouterLink, { to: "/privacy", onClick: () => trackFooterLinkClick('privacy') },
                        React.createElement(FormattedMessage, { ...messages.privacy })),
                    React.createElement(Link, { href: "https://help.sweatco.in", onClick: () => trackFooterLinkClick('help'), rel: "noreferrer noopener", target: "_blank" },
                        React.createElement(FormattedMessage, { ...messages.help })),
                    React.createElement(Link, { href: "https://blog.sweatco.in", onClick: () => trackFooterLinkClick('blog'), rel: "noreferrer noopener", target: "_blank" },
                        React.createElement(FormattedMessage, { ...messages.blog })),
                    React.createElement(Link, { href: "https://dev.sweatco.in", onClick: () => trackFooterLinkClick('dev'), rel: "noreferrer noopener", target: "_blank" },
                        React.createElement(FormattedMessage, { ...messages.dev })),
                    React.createElement(Link, { href: "https://promote.sweatco.in/", onClick: () => trackFooterLinkClick('influencers'), rel: "noreferrer noopener", target: "_blank" },
                        React.createElement(FormattedMessage, { ...messages.influencers })),
                    React.createElement(StyledRouterLink, { onClick: () => trackFooterLinkClick('select_your_country'), to: "/select-your-country" },
                        React.createElement("span", null, country === 'int' ? 'International' : CountriesList[country].name))),
                React.createElement(Column, null,
                    React.createElement(Link, { href: "/#vision", onClick: (event) => menuItemOnClick(event, 'vision', 1) },
                        React.createElement(FormattedMessage, { ...messages.vision })),
                    React.createElement(Link, { href: "/#how-it-works", onClick: (event) => menuItemOnClick(event, 'how-it-works', 3) },
                        React.createElement(FormattedMessage, { ...messages.how_it_works })),
                    React.createElement(Link, { href: "/#facts", onClick: (event) => menuItemOnClick(event, 'facts', 2) },
                        React.createElement(FormattedMessage, { ...messages.facts })),
                    React.createElement(Link, { href: "/#partners", onClick: (event) => menuItemOnClick(event, 'partners', 4) },
                        React.createElement(FormattedMessage, { ...messages.partners })),
                    React.createElement(Socials, null,
                        React.createElement(Linkedin, { itemProp: "sameAs", target: "_blank", href: "https://linkedin.com/company/sweatcoin", alt: "LinkedIn link", rel: "noreferrer noopener", onClick: () => trackFooterLinkClick('linkedin') }),
                        React.createElement(Tiktok, { itemProp: "sameAs", target: "_blank", href: "https://www.tiktok.com/@sweatcoin", alt: "Tiktok link", rel: "noreferrer noopener", onClick: () => trackFooterLinkClick('tiktok') }),
                        React.createElement(Instagram, { itemProp: "sameAs", target: "_blank", href: "https://www.instagram.com/sweatcoin/", alt: "Instagram link", rel: "noreferrer noopener", onClick: () => trackFooterLinkClick('instagram') }),
                        React.createElement(Twitter, { itemProp: "sameAs", target: "_blank", href: "https://twitter.com/sweatcoin", alt: "Twitter link", rel: "noreferrer noopener", onClick: () => trackFooterLinkClick('twitter') }),
                        React.createElement(Facebook, { itemProp: "sameAs", target: "_blank", href: "https://www.facebook.com/sweatcoin", alt: "Facebook link", rel: "noreferrer noopener", onClick: () => trackFooterLinkClick('facebook') })))))));
    function select(selector) {
        if (typeof document !== 'undefined') {
            return document.getElementById(selector);
        }
        else {
            return [];
        }
    }
    function scrollTo(section_id) {
        const scroll_to = select(section_id);
        if (scroll_to) {
            const scrolled = window.pageYOffset || document.documentElement.scrollTop;
            window.scrollTo({
                top: scrolled + scroll_to.getBoundingClientRect().top + 1,
                behavior: 'smooth',
            });
        }
    }
    function trackMenuItemClick(value) {
        analytics.track(`footer_menu_item_${value}`, 'click', {
            origin: location.origin,
            page: location.pathname,
        });
    }
    function trackFooterLinkClick(value) {
        analytics.track(`footer_link_${value}`, 'click', {
            origin: location.origin,
            page: location.pathname,
        });
    }
    function menuItemOnClick(event, section_id, value) {
        if (path === '/')
            event.preventDefault();
        trackMenuItemClick(value);
        scrollTo(section_id);
    }
};
const Wrapper = styled.div `
  border-top: 1px solid rgba(170, 170, 170, 0.2);
  margin-top: 50px;
  padding-bottom: 50px;
  background: ${({ bgColor }) => (bgColor ? bgColor : 'rgb(3, 37, 85)')};

  @media ${desktop()} {
    margin-top: 70px;
    padding-bottom: 100px;
  }
`;
const Content = styled.div `
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 60px;

  @media ${desktop()} {
    flex-direction: row;
    width: 980px;
    padding-bottom: 0;
  }
`;
const Side = styled.div `
  padding-top: 40px;
  display: flex;
  align-items: start;

  @media ${desktop()} {
    flex: 0.5;
  }
`;
const Copyright = styled.div `
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 0 16px;

  @media ${desktop()} {
    margin: 0;
  }
`;
const SWC = styled.img `
  width: 40px;
  display: inline-block;
  margin-right: 20px;
`;
const Text = styled.p `
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  line-height: 1.2;
  text-align: left;
`;
const Column = styled.div `
  width: 232px;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;
const LinkStyle = css `
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 16px;

  span {
    color: inherit;
  }
`;
const StyledRouterLink = styled(NavLink) `
  ${LinkStyle}
`;
const Link = styled.a `
  ${LinkStyle}
`;
const Socials = styled.div `
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  left: 16px;
  right: 16px;

  @media ${desktop()} {
    position: static;
    margin-top: auto;
    margin-bottom: 15px;
    justify-content: space-between;
  }
`;
const Icon = styled.a `
  display: block;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  width: 28px;
  height: 28px;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.7;
  }
`;
const Instagram = styled(Icon) `
  background-image: url(${instagram});
`;
const Twitter = styled(Icon) `
  background-image: url(${x});
  transform: scale(0.8);
`;
const Facebook = styled(Icon) `
  background-image: url(${facebook});
`;
const Linkedin = styled(Icon) `
  background-image: url(${linkedin});
`;
const Tiktok = styled(Icon) `
  background-image: url(${tiktok});
  transform: scale(1.4);
`;
export default Footer;
