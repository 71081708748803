import colorInterpolate from 'color-interpolate';
import _ from 'lodash';
import React from 'react';
import { Motion, spring } from 'react-motion'; // TODO migrate to something newer, doesn't support new React
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bind as SizeSensor } from 'size-sensor';
import styled from 'styled-components';
import * as HomeActions from 'actions/home';
import SECTIONS from 'constants/sections';
import { media, sizes } from '../../../components/responsive';
const sectionsSelector = (state) => _.get(state, 'home.sections');
const backgroundImageIdSelector = (state) => _.get(state, 'home.backgroundImageId', 0);
const prevBackgroundImageIdSelector = (state) => _.get(state, 'home.prevBackgroundImageId', 0);
const mapStateToProps = createStructuredSelector({
    sections: sectionsSelector,
    backgroundId: backgroundImageIdSelector,
    prevBackgroundId: prevBackgroundImageIdSelector,
});
const mapDispatchToProps = (dispatch) => ({
    setActiveSection: (section) => dispatch(HomeActions.setActiveSection(section)),
});
class Background extends React.PureComponent {
    state = {
        init: null,
        images: null,
        isInit: false,
        isResizing: false,
        currentSection: null,
    };
    containerRef = React.createRef();
    section_data = [];
    componentDidUpdate() {
        if (!this.state.isInit && this.props.sections) {
            this.init();
        }
    }
    init() {
        this.initSections();
        this.renderBackground();
        this.scrollListener();
        this.setState({ isInit: true });
    }
    componentDidMount() {
        if (!this.state.isInit && this.props.sections) {
            this.init();
        }
    }
    componentWillUnmount() {
        this.removeListeners();
    }
    removeListeners = () => {
        window.removeEventListener('scroll', this.onScroll);
        this.unbindResizeSensor && this.unbindResizeSensor();
    };
    // Get color for the left side of background
    getLeftColor = (transition) => {
        if (this.section_data.length === 0)
            return SECTIONS[Object.keys(SECTIONS)[0]].left.color;
        const getSectionColor = (index) => this.section_data[index].left.color;
        if (Number.isInteger(transition)) {
            return getSectionColor(transition);
        }
        else {
            const currentSection = Math.floor(transition);
            const interpolator = colorInterpolate([getSectionColor(currentSection), getSectionColor(currentSection + 1)]);
            return interpolator(transition - currentSection);
        }
    };
    // Get gradient for the right side of background
    getRightGradient = (transition) => {
        if (this.section_data.length === 0)
            return SECTIONS[Object.keys(SECTIONS)[0]].right.gradient;
        const getSectionGradient = (index) => this.section_data[index].right.gradient;
        if (Number.isInteger(transition)) {
            return getSectionGradient(transition);
        }
        else {
            const currentSection = Math.floor(transition);
            return Helpers.gradient2gradient(getSectionGradient(currentSection), getSectionGradient(currentSection + 1), (transition - currentSection) * 100);
        }
    };
    // Get image/images for the right side of background
    getRightImage = (transition, slide = { from: 0, to: 0, progress: 0 }) => {
        // if no sections loaded (server side)
        if (this.section_data.length === 0) {
            const section = SECTIONS[Object.keys(SECTIONS)[0]];
            return [{ url: section.image, size: section.image_size, opacity: 1 }];
        }
        const getSectionImage = (index, backgroundId = 0) => {
            const { image, imageSize } = this.section_data[index].right;
            if (Array.isArray(image)) {
                const image_id = backgroundId;
                const image_data = image[image_id];
                // if no image return null
                if (!image_data) {
                    return { url: null, size: null };
                }
                const section_image = image_data.url || image_data;
                const section_image_size = image_data.image_size || null;
                const section_image_opacity = image_data.opacity || 1;
                return { url: section_image, size: section_image_size, opacity: section_image_opacity };
            }
            return { url: image, size: imageSize };
        };
        // Detect "from" and "to" images
        const isSectionTransition = !Number.isInteger(transition);
        const isSlideTransition = !Number.isInteger(slide.progress);
        const currentSection = Math.floor(transition);
        // Transition between sections
        if (isSectionTransition) {
            const currentImage = getSectionImage(currentSection, slide.to);
            const nextImage = getSectionImage(currentSection + 1, slide.to);
            const { opacity } = currentImage;
            // if next section have same image dont make transition
            if (currentImage.url === nextImage.url)
                return [{ url: currentImage.url, size: currentImage.size, opacity: getOpacity(opacity, 1) }];
            // Get transition progress
            transition = transition - currentSection;
            return [
                { url: currentImage.url, size: currentImage.size, opacity: getOpacity(opacity, 1 - transition) },
                { url: nextImage.url, size: nextImage.size, opacity: getOpacity(nextImage.opacity, transition) },
            ];
        }
        else if (isSlideTransition) {
            // Transition in one section
            // const [currentImage, nextImage] = getSlideImages(currentSection, slide)
            const currentImage = getSectionImage(currentSection, slide.from);
            const nextImage = getSectionImage(currentSection, slide.to);
            // if next section have same image dont make transition
            if (currentImage.url === nextImage.url)
                return [{ url: currentImage.url, size: currentImage.size, opacity: getOpacity(currentImage.opacity, 1) }];
            // Get transition progress
            const transition = slide.progress;
            return [
                { url: currentImage.url, size: currentImage.size, opacity: getOpacity(currentImage.opacity, 1 - transition) },
                { url: nextImage.url, size: nextImage.size, opacity: getOpacity(nextImage.opacity, transition) },
            ];
        }
        else {
            // Static image (no transition)
            const { url, size, opacity } = getSectionImage(transition, slide.to);
            return [{ url, size, opacity: getOpacity(opacity, 1) }];
        }
        function getOpacity(defaultOpacity = 1, opacity) {
            return defaultOpacity * opacity;
        }
    };
    interpolateTransition = (from, to, progress) => (progress - from) / (to - from);
    render() {
        const { backgroundId, prevBackgroundId } = this.props;
        const style = {
            transition: spring(this.state.currentSection || 0, { stiffness: 130, damping: 30 }),
            slide: spring(backgroundId),
        };
        return (React.createElement(Container, { ref: this.containerRef },
            React.createElement(Motion, { key: this.state.init, style: style }, ({ transition, slide }) => {
                const leftColor = this.getLeftColor(transition);
                const rightGradient = this.getRightGradient(transition);
                const slideProgress = this.interpolateTransition(prevBackgroundId, backgroundId, slide);
                const images = this.getRightImage(transition, {
                    from: prevBackgroundId,
                    to: backgroundId,
                    progress: slideProgress,
                });
                const BackgroundImages = images.map((image) => image.url ? (React.createElement("div", { className: "background__image", key: image.url, style: {
                        backgroundImage: `url(${image.url})`,
                        backgroundSize: image.size ? image.size : null,
                        opacity: image.opacity,
                    } })) : null);
                //side effect
                this.renderHeader(leftColor, rightGradient, transition);
                return (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "background__left", id: "background-left", style: { backgroundColor: leftColor } }),
                    React.createElement("div", { className: "background__right", id: "background-right", style: { background: rightGradient } }, BackgroundImages)));
            })));
    }
    renderHeader = (color, gradient) => {
        const left = Helpers.select('#header__background-left')[0];
        const logo = Helpers.select('#box-logo')[0];
        const right = Helpers.select('#header__background-right')[0];
        const menuItems = Helpers.select('#menu .menu__item');
        if (left) {
            left.style.backgroundColor = color;
        }
        if (logo) {
            logo.style.backgroundColor = color;
        }
        // Set color for active menuItem and reset color for other menuItems
        menuItems.forEach((element, id) => {
            const isActive = id === _.get(this.section_data, `[${this.state.currentSection}].menuItem`, null);
            element.style.backgroundColor = isActive ? color : null;
        });
        if (right) {
            right.style.background = gradient;
        }
    };
    setMenuColorDom = (color) => {
        const menu = Helpers.select('#menu')[0];
        if (menu) {
            menu.setAttribute('data-background', color);
        }
    };
    getSectionById = (id) => this.props.sections[Object.keys(this.props.sections)[id]];
    initSections = () => {
        const scrolled = window.pageYOffset || document.documentElement.scrollTop;
        this.setState({ isResizing: true });
        // TODO rewrite to refs and remove 'section' from all Sections className
        this.$sections = Helpers.select('.section');
        this.section_data = [];
        _.forEach(this.$sections, ($section, id) => {
            const section_rect = $section.getBoundingClientRect();
            const section = this.getSectionById(id);
            const right_image = _.get(section, 'right.image');
            const rightImageSize = _.get(section, 'right.image_size');
            if (Array.isArray(right_image)) {
                right_image.map((image) => (image ? Helpers.preloadImage(image.url || image) : null));
            }
            else {
                Helpers.preloadImage(right_image);
            }
            this.section_data.push({
                ...section,
                id,
                top: section_rect.top + scrolled,
                bottom: section_rect.bottom + scrolled,
                height: section_rect.height,
                left: {
                    color: _.get(section, 'left.color'),
                },
                // TODO if 'color' take gradient options(deg, position) from the next section (for pretty animation)
                right: {
                    gradient: _.get(section, 'right.gradient') || Helpers.color2gradient(_.get(section, 'right.color')),
                    image: right_image,
                    imageSize: rightImageSize,
                },
            });
        });
        this.setState({ isResizing: false }, () => {
            this.onScroll();
        });
    };
    setActiveSection = (section) => {
        if (section.id !== this.state.currentSection) {
            this.setState((prevState) => ({
                currentSection: section.id,
                init: prevState.init === null ? section.id : prevState.init,
            }));
            this.props.setActiveSection(section);
            this.setMenuColorDom(_.get(section, 'left.color'));
        }
    };
    renderBackground = () => {
        if (this.state.isResizing) {
            return;
        }
        requestAnimationFrame(() => {
            const scrolled = window.pageYOffset || document.documentElement.scrollTop;
            const active_section = _.last(_.filter(this.section_data, (section) => section.top <= scrolled)) || this.section_data[0];
            if (!active_section) {
                console.warn('something wrong with detecting active section');
                return false;
            }
            const next_section = this.section_data[active_section.id + 1] || null;
            let progress_offset;
            // if mobile layout
            if (window.innerWidth < sizes.desktop) {
                progress_offset = 90;
            }
            else {
                progress_offset = 90;
            }
            let progress = ((scrolled - active_section.top) / active_section.height) * 100;
            // fix for TextBar
            if (progress > 100) {
                progress = 100;
            }
            // if current section scrolled more than progress_offset, render next section
            const render_section = next_section && progress > progress_offset ? next_section : active_section;
            this.setActiveSection(render_section);
        });
    };
    onScroll = _.throttle(this.renderBackground, 150, { trailing: true, leading: true });
    onResize = _.throttle(this.initSections, 150, { trailing: true });
    scrollListener = () => {
        window.addEventListener('scroll', this.onScroll);
        this.unbindResizeSensor = SizeSensor(document.getElementsByTagName('body')[0], this.onResize);
    };
}
export const Helpers = {
    gradient_placeholder: {
        deg: 135,
        left_color: '#FFFFFF',
        left_position: 0,
        right_color: '#FFFFFF',
        right_position: 0,
    },
    select: (selector) => {
        if (typeof document !== 'undefined') {
            return document.querySelectorAll(selector);
        }
        else {
            return [];
        }
    },
    hex2rgb: (hex) => {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16),
            }
            : null;
    },
    rgb2hex: (rgb) => {
        function hex(color) {
            let hex = Number(color).toString(16);
            if (hex.length < 2) {
                hex = '0' + hex;
            }
            return hex;
        }
        const { r, g, b } = rgb, red = hex(r), green = hex(g), blue = hex(b);
        return `#${red}${green}${blue}`;
    },
    rgb2string: (color) => `rgb(${color.r || color[0]}, ${color.g || color[1]}, ${color.b || color[2]})`,
    parseGradient: (gradient) => {
        const regex = /gradient\((\d+)deg, #([a-zA-Z\d]+) (\d+)%, #([a-zA-Z\d]+) (\d+)%/;
        const parsed = gradient.match(regex);
        parsed.shift();
        return {
            deg: Number(parsed.shift()),
            left_color: parsed.shift(),
            left_position: Number(parsed.shift()),
            right_color: parsed.shift(),
            right_position: Number(parsed.shift()),
        };
    },
    color2gradient: (color) => Helpers.buidGradient({ ...Helpers.gradient_placeholder, left_color: color, right_color: color }),
    buidGradient: ({ deg, left_color, left_position, right_color, right_position }) => `linear-gradient(${deg}deg, ${left_color} ${left_position}%, ${right_color} ${right_position}%)`,
    color2color: (from, to, progress) => {
        from = Helpers.hex2rgb(from);
        to = Helpers.hex2rgb(to);
        const deltas = [(to.r - from.r) / 100, (to.g - from.g) / 100, (to.b - from.b) / 100];
        return {
            r: Math.floor(from.r + deltas[0] * progress),
            g: Math.floor(from.g + deltas[1] * progress),
            b: Math.floor(from.b + deltas[2] * progress),
        };
    },
    gradient2gradient: (from, to, progress) => {
        from = Helpers.parseGradient(from);
        to = Helpers.parseGradient(to);
        const deltas = {
            deg: (to.deg - from.deg) / 100,
            left_position: (to.left_position - from.left_position) / 100,
            right_position: (to.right_position - from.right_position) / 100,
        };
        const new_gradient = {
            deg: Math.floor(from.deg + deltas.deg * progress),
            left_color: Helpers.rgb2hex(Helpers.color2color(from.left_color, to.left_color, progress)),
            right_color: Helpers.rgb2hex(Helpers.color2color(from.right_color, to.right_color, progress)),
            left_position: Math.floor(from.left_position + deltas.left_position * progress),
            right_position: Math.floor(from.right_position + deltas.right_position * progress),
        };
        return Helpers.buidGradient(new_gradient);
    },
    preloaded_images: [],
    preloadImage: (link) => {
        if (!link)
            return;
        if (Helpers.preloaded_images.indexOf(link) !== -1)
            return;
        Helpers.preloaded_images.push(link);
        let img = new Image();
        img.onload = () => (img = null);
        img.src = link;
    },
};
const Container = styled.div `
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .background__left,
  .background__right {
    height: 100vh;
    width: 50%;
    position: relative;
  }

  .background__image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: auto 60%;
    background-position-x: 50%;
    background-position-y: 100%;
    background-repeat: no-repeat;

    ${media.desktop `background-size: 100%;`};
  }
`;
export default connect(mapStateToProps, mapDispatchToProps)(Background);
