import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import scr4 from 'assets/AndroidInstructions/huawei/huawei_app_launch_1.png';
import scr5 from 'assets/AndroidInstructions/huawei/huawei_app_launch_2.png';
import scr6 from 'assets/AndroidInstructions/huawei/huawei_app_launch_3.png';
import scr1 from 'assets/AndroidInstructions/huawei/huawei_battery_1.png';
import scr2 from 'assets/AndroidInstructions/huawei/huawei_battery_2.png';
import scr3 from 'assets/AndroidInstructions/huawei/huawei_battery_3.png';
import scr7 from 'assets/AndroidInstructions/huawei/huawei_recent_1.png';
import scr8 from 'assets/AndroidInstructions/huawei/huawei_recent_2.png';
import scr9 from 'assets/AndroidInstructions/huawei/huawei_recent_3.png';
import placeholderSrc from 'assets/AndroidInstructions/screenshot_placeholder.png';
import SliderBlock from '../SliderBlock';
import { AdviceNotSuitable, Common, Divider, Footer, List, ListItem, SamsungDivider, SettingsButton, Text, Title, } from './common';
const messages = defineMessages({
    cardsBatteryDescription_1: "In Settings search 'battery' and go to Battery optimisation",
    cardsBatteryDescription_2: 'Select All apps and search Sweatcoin',
    cardsBatteryDescription_3: "Select Don't allow",
    cardsAppLaunch_1: "In Settings search 'app launch' and go to 'App Launch'",
    cardsAppLaunch_2: 'App Launch',
    cardsAppLaunch_3: "Turn switch to 'Manually' and keep all switches enabled",
    cardsRecent_1: 'Open Recent Apps',
    cardsRecent_2: 'Hold the app card and pull down',
    cardsRecent_3: 'Lock icon should appear',
    batteryTitle: 'Manage battery settings',
    settingsButton: 'Go to settings',
    allowAutostart: 'Allow autostart',
    lock: 'Lock Sweatcoin as recent app',
    alternative: 'Alternatively try this:',
    disablePowerGenie: 'Disable Power Genie',
    settings: 'Settings',
    apps: 'Apps',
    power: 'If Power Genie is installed, disable it',
    allowBg: 'Allow running in background',
    sweatcoin: 'Sweatcoin',
    battery: 'Battery',
    keepRunning: 'Enable “Keep running after screen off”, disable “Power-intensive prompt”',
    removeRestrictions: 'Remove restrictions',
    advanced: 'Advanced',
    ignoreOptimisations: 'Ignore optimisations',
    allowed: 'Allowed',
    allApps: 'All apps',
    sweatcoinAllowed: 'Set Sweatcoin to Allowed',
    specialAccess: 'Special access',
    ignore: 'Ignore battery optimisation',
    allowAll: 'Select allow for Sweatcoin',
    powerPlan: 'Adjust power plan',
    advancedSettings: 'Advanced Settings',
    batteryManager: 'Battery Manager',
    perfomancePlan: 'Set Power plan to Performance, set Sweatcoin as Protected app',
    protectedApps: 'Protected Apps',
    switchList: 'Find Sweatcoin in the list and enable the switch',
    allowBgActivity: 'Allow background activity',
    launch: 'Launch Sweatcoin',
    square: 'Press the phone’s hardware app list button (square icon)',
    card: 'Find the Sweatcoin card',
    locked: 'Press the lock icon next to the name to show LOCKED… or swipe down the Sweatcoin card to lock (lock icon should appear next to the App name)',
});
const CardsBattery = [
    {
        screenshot: scr1,
        description: React.createElement(FormattedMessage, { ...messages.cardsBatteryDescription_1 }),
    },
    {
        screenshot: scr2,
        description: React.createElement(FormattedMessage, { ...messages.cardsBatteryDescription_2 }),
    },
    {
        screenshot: scr3,
        description: React.createElement(FormattedMessage, { ...messages.cardsBatteryDescription_3 }),
    },
];
const AppLaunch = [
    {
        screenshot: scr4,
        description: React.createElement(FormattedMessage, { ...messages.cardsAppLaunch_1 }),
    },
    {
        screenshot: scr5,
        description: React.createElement(FormattedMessage, { ...messages.cardsAppLaunch_2 }),
    },
    {
        screenshot: scr6,
        description: React.createElement(FormattedMessage, { ...messages.cardsAppLaunch_3 }),
        top: '-40%',
    },
];
const CardsRecent = [
    {
        screenshot: scr7,
        description: React.createElement(FormattedMessage, { ...messages.cardsRecent_1 }),
    },
    {
        screenshot: scr8,
        description: React.createElement(FormattedMessage, { ...messages.cardsRecent_2 }),
        top: '-40%',
    },
    {
        screenshot: scr9,
        description: React.createElement(FormattedMessage, { ...messages.cardsRecent_3 }),
    },
];
let trackedEvents = [];
const Huawei = (props) => {
    const { openSettings, trackEvent } = props;
    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 280,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    useEffect(() => {
        // cleanup
        return () => {
            trackedEvents = [];
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Common, { featureAccelerometerTracker: props.featureAccelerometerTracker, trackEvent: props.trackEvent, openSettings: props.openSettings, isFooterDividerDefault: true }),
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.batteryTitle })),
        React.createElement(SliderBlock, { trackedEvents: trackedEvents, trackEvent: trackEvent, index: 0, settings: sliderSettings, cardArray: buildArray(CardsBattery), cards: CardsBattery }),
        React.createElement(SettingsButton, { openSettings: openSettings, style: { marginTop: '16px', marginBottom: '16px' } },
            React.createElement(FormattedMessage, { ...messages.settingsButton })),
        React.createElement(Divider, null),
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.allowAutostart })),
        React.createElement(SliderBlock, { trackedEvents: trackedEvents, trackEvent: trackEvent, index: 0, settings: sliderSettings, cardArray: buildArray(AppLaunch), cards: AppLaunch }),
        React.createElement(SettingsButton, { openSettings: openSettings, style: { marginTop: '16px', marginBottom: '16px' } },
            React.createElement(FormattedMessage, { ...messages.settingsButton })),
        React.createElement(Divider, null),
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.lock })),
        React.createElement(SliderBlock, { trackedEvents: trackedEvents, trackEvent: trackEvent, index: 0, settings: sliderSettings, cardArray: buildArray(CardsRecent), cards: CardsRecent }),
        React.createElement(SettingsButton, { openSettings: openSettings, style: { marginTop: '16px', marginBottom: '16px' } },
            React.createElement(FormattedMessage, { ...messages.settingsButton })),
        React.createElement(Divider, null),
        React.createElement(Title, { style: { marginTop: '32px', marginBottom: '16px' } },
            React.createElement(FormattedMessage, { ...messages.alternative })),
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.disablePowerGenie })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.apps })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.power }))),
        React.createElement(SettingsButton, { openSettings: props.openSettings, style: { marginTop: '16px', marginBottom: '16px' } }),
        React.createElement(Divider, null),
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.allowBg })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.apps })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.sweatcoin })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.battery })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.keepRunning }))),
        React.createElement(SettingsButton, { openSettings: props.openSettings, style: { marginTop: '16px', marginBottom: '16px' } }),
        React.createElement(Divider, null),
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.removeRestrictions })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.apps })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.advanced })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.ignoreOptimisations })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.allowed })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.allApps })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.sweatcoinAllowed }))),
        React.createElement(Text, null,
            React.createElement(FormattedMessage, { ...messages.alternative })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.apps })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.specialAccess })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.ignore })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.allowAll }))),
        React.createElement(Text, null,
            React.createElement(FormattedMessage, { ...messages.alternative })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.apps })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.advanced })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.ignoreOptimisations })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.allowed })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.allApps })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.sweatcoinAllowed }))),
        React.createElement(SettingsButton, { openSettings: props.openSettings, style: { marginTop: '16px', marginBottom: '16px' } }),
        React.createElement(Divider, null),
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.powerPlan })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.advancedSettings })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.batteryManager })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.perfomancePlan }))),
        React.createElement(Text, null,
            React.createElement(FormattedMessage, { ...messages.alternative })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.advancedSettings })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.batteryManager })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.protectedApps })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.switchList }))),
        React.createElement(SettingsButton, { openSettings: props.openSettings, style: { marginTop: '16px', marginBottom: '16px' } }),
        React.createElement(Divider, null),
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.allowBgActivity })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.launch })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.square })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.card })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.locked }))),
        React.createElement(SettingsButton, { openSettings: props.openSettings, style: { marginTop: '16px', marginBottom: '16px' } }),
        React.createElement(SamsungDivider, null),
        React.createElement(Footer, { trackEvent: props.trackEvent }),
        React.createElement(AdviceNotSuitable, { trackEvent: props.trackEvent })));
};
const Card = styled.div `
  padding: 16px 0;
  width: calc(100% - 32px) !important;
  margin: 0 16px 0 0;
  border-radius: 9px;
  background: rgba(255, 255, 255, 0.1);
  outline: none;
  position: relative;
`;
const CardImage = styled.div `
  width: calc(100% - 64px);
  max-width: 264px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
`;
const Screenshot = styled.img `
  position: absolute;
  top: ${(props) => props.top};
  left: 0;
  width: 100%;
`;
const ScreenshotWrapper = styled.div `
  position: absolute;
  top: 9%;
  left: 16.2%;
  width: 68%;
  bottom: 1px;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
`;
const Placeholder = styled.img `
  width: 100%;
  position: relative;
`;
const renderCard = (index, { src, top = 0 }) => (React.createElement(Card, { key: index },
    React.createElement(CardImage, null,
        React.createElement(ScreenshotWrapper, null,
            React.createElement(Screenshot, { src: src, top: top })),
        React.createElement(Placeholder, { src: placeholderSrc }))));
const buildArray = (cards) => cards.map((card, index) => renderCard(index, { src: card.screenshot, top: card.top || 0 }));
export default Huawei;
