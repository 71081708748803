import queryString from 'query-string';
export function headers(extraHeaders = {}) {
    return ({ locale, session }) => {
        const defaultHeaders = {
            'Content-Type': 'application/json',
        };
        if (locale) {
            defaultHeaders['Accept-Language'] = locale;
        }
        return {
            ...defaultHeaders,
            ...session,
            ...extraHeaders,
        };
    };
}
export function path(pathname, query = undefined) {
    const fullPath = pathname + (query ? `?${queryString.stringify(query)}` : '');
    return function ({ config }) {
        if (process.browser) {
            return fullPath;
        }
        if (config.proxy) {
            // debug
            // if (pathname === '/landing/global_stats.json') {
            //   return `https://production.sweatco.in${pathname}`
            // }
            return `http://localhost:${config.port}${fullPath}`;
        }
        return `${config.apiUrl}${fullPath}`;
    };
}
